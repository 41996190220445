import React, { useState, useEffect } from "react";
import { graphql } from "gatsby"
import Header from "../components/Header/Header";
import PropertyBreadcrumb from "../components/PropertyDetails/PropertyBreadcrumb";
import PropertyBanner from "../components/PropertyDetails/PropertyBanner";
import PropertyDescription from "../components/PropertyDetails/PropertyDescription";
import SimilarProperties from "../components/PropertyDetails/SimilarProperties";
import Page404 from "../pages/404";
import BookaViewing from "../components/PropertyDetails/BookaViewing";
import { propertyDetImage } from "../components/Seo/propertyDetailsSEO";
import Footer from "../components/Footer/Footer";
import BrandLogo from "../images/icons/logo_black.svg";
import { capitalize } from "lodash";
import SEO from "../components/Seo/seo"

export const PROPERTY_DETAILS = graphql`
  query ($id: String) {
    allProperties(filter: { _id: { eq: $id } }) {
      edges {
        node {
          _id
          search_type
          status
          department
          publish
          price
          currency
          display_address
          area
          bedroom
          bathroom
          reception
          building
          extra {
            referenceNumber
            sub_community_propertyfinder
            community_propertyfinder
          }
          floorarea_min
          crm_negotiator_id {
            email
            mobile
            name
            phone
          }
          latitude
          longitude
          long_description
          images {
            url
            srcUrl
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              format
              transform
            }
          }
        }
      }
    }
  }
`

const PropertyDetailsTemplate = props => {
    const data = props?.data?.allProperties

    return (
        <React.Fragment>
            <div className="property-details-template">
                <Header layout={"property-details-template"} />

                {
                    (
                        data?.edges?.map(({ node }, i) => {
                          const building = node?.building;
                          var searchaction = node.search_type === "sales" ? "for sale" : "to let";
                          var metatile = `${capitalize(building ? building.replace(/-/g, ' ') : 'property')} ${searchaction} with ${node.bedroom} bedrooms in ${node.display_address} at AED ${node.price.toLocaleString()}`;
                          var metadesc = `Know the details of ${capitalize(building?.replace(/-/g, ' '))} ${searchaction} with ${node.bedroom} bedrooms in ${node.display_address} at AED ${node.price.toLocaleString()}. Book a viewing to get assistance in finding the right ${capitalize(building?.replace(/-/g, ' '))} for you.`;
                          var metaimg = node?.images && node?.images.length > 0 ? propertyDetImage(node) : "";

                            return (
                                <>
                                    <SEO title={metatile} description={metadesc} image={metaimg}/>

                                    <PropertyBreadcrumb propertyData={node} />

                                    <PropertyBanner propertyData={node} normalImg />

                                    <PropertyDescription propertyData={node} />

                                    <SimilarProperties propertyData={node} />

                                    <BookaViewing propertyData={node} />
                                </>
                            )
                        })
                    )
                }

                <Footer layout={"property-details"} categoryType={data.edges[0].node.department === "residential" || data.edges[0].node.department === "commercial" ? "category1" : "category4"}  />
            </div>
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate